<div class="flex flex-col h-full mb-48">
    <div class="flex flex-row justify-between">
        <h1 class="m-10 p-10 text-xl font-bold text-gray">LTC Prequalifying Questionnaire</h1>
        <button mat-button mat-dialog-close tabindex="-1" type="button" class="m-10 ml-auto icon-button is-primary is-inverted">
            <i class="material-icons">close</i>
        </button>
    </div>
    <div class="flex flex-row justify-center">
        <div (click)="openSingleLTCForm()" class="flex flex-col m-20 p-50 overflow-hidden h-full w-256 transition-colors duration-150 
        focus:outline-none border border-solid border-gray-lighter hover:bg-blue-lighter hover:bg-opacity-25
         justify-center items-center cursor-pointer">
            <span class="m-10 text-3xl material-icons text-blue-dark">
            person
            </span>
            <p class="text-xl text-blue-dark">
                Single
            </p>
        </div>
        <div (click)="openJointLTCForm()" class="flex flex-col m-20 p-50 overflow-hidden h-full w-256 transition-colors duration-150 
        focus:outline-none border border-solid border-gray-lighter hover:bg-blue-lighter hover:bg-opacity-25
         justify-center items-center cursor-pointer">
            <span class="m-10 text-3xl material-icons text-blue-dark">
            people
            </span>
            <p class="text-xl text-blue-dark">
                Joint
            </p>
        </div>
    </div>
</div>