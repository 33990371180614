<section class="relative flex flex-col h-full bg-center bg-no-repeat bg-cover bg-gray-wave" fadeIn>
  <button class="absolute top-0 right-0 flex-none m-20 icon-button is-primary is-inverted" aria-label="Close"
          mat-dialog-close type="button">
    <i class="material-icons">close</i>
  </button>
  <div class="justify-center p-20 dialog-content gap-y-30 md:gap-y-20 md:p-30">
    <div class="flex flex-col justify-around gap-20 md:flex-row">
      <div class="flex flex-col justify-between w-full gap-y-20 md:w-2/5">
        <div class="flex flex-col items-center">
          <img class="h-auto w-200" aria-label="Policygenius logo" alt="Policygenius logo" src="./assets/images/logos/policygenius-logo.png">
        </div>
        <p>Use the Policygenius platform to simply hand off your client to Policygenius and they will handle everything from applying all the way to issuance.</p>
        <a mat-button class="w-full mx-auto button is-primary is-lg" aria-label="Open Policygenius" target="_blank" href="https://partners.policygenius.com/ ">Launch Policygenius</a>
      </div>
      <span class="hidden w-px h-full my-auto md:block bg-gray-lighter"></span>
      <span class="block w-full h-px mx-auto md:hidden bg-gray-light"></span>
      <div class="flex flex-col justify-between w-full gap-y-20 md:w-2/5">
        <div class="flex flex-col items-center">
          <img class="h-auto w-200" aria-label="iPipeline logo" alt="iPipeline logo" src="./assets/images/logos/ipipeline-logo.png">
        </div>
        <p class="mb-20">A partnership that makes it faster and easier for your clients to purchase life insurance and wealth solutions is just a few keystrokes away.</p>
        <button mat-button class="w-full mx-auto button is-primary is-lg" aria-label="Open iPipeline" (click)="openIPipelineSso()">Launch iPipeline</button>
      </div>
    </div>
  </div>
</section>