import { AfterViewInit, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { fadeIn } from 'src/app/animations';
import { SsoService } from 'src/app/core/services/sso.service';

@Component({
  animations: [fadeIn],
  selector: 'app-term-life-sso-modalterm-life-sso-modal',
  templateUrl: './term-life-sso-modalterm-life-sso-modal.component.html'
})
export class TermLifeSsoModaltermLifeSsoModalComponent implements AfterViewInit {

  constructor(
    private dialogRef: MatDialogRef<TermLifeSsoModaltermLifeSsoModalComponent>,
    private ssoService: SsoService
  ) { }

  ngAfterViewInit(): void {
    const width = '60rem';
    const height = '40rem';
    this.dialogRef.updateSize(width, height);
  }

  openIPipelineSso():void{
    this.ssoService.iPipelineLifeQuotesSso();
  }
}
